import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bb95f76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-h-screen overflow-hidden" }
const _hoisted_2 = { class: "flex flex-initial flex-shrink-0 bg-gray-300" }
const _hoisted_3 = { class: "flex flex-col flex-auto overflow-y-hidden relative" }
const _hoisted_4 = { class: "flex flex-auto h-full z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_EnvironmentRibbon = _resolveComponent("EnvironmentRibbon")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Alerts = _resolveComponent("Alerts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["backdrop", { 'hidden': !_ctx.showBackDrop }])
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createElementVNode("main", {
        class: _normalizeClass(["flex flex-col bg-gray-100 h-screen max-h-screen", { 'pt-20': _ctx.isAuthenticated }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Menu)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_EnvironmentRibbon),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_view)
          ])
        ])
      ], 2),
      _createVNode(_component_Alerts, { onSetBackDrop: _ctx.setBackDrop }, null, 8, ["onSetBackDrop"])
    ])
  ], 64))
}
import Login from '@/views/Login.vue';
import Unauthorized from '@/views/Unauthorized.vue';

const StandaloneRoutes = [
    {
        path: '/',
        redirect: {
            name: 'Login'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized,
    },
];

export default StandaloneRoutes;

import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1791b3fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesome = _resolveComponent("FontAwesome")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled || _ctx.loading
  }, [
    (!_ctx.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : (_openBlock(), _createBlock(_component_FontAwesome, {
          key: 1,
          class: "animate-spin",
          icon: "circle-notch"
        }))
  ], 8, _hoisted_1))
}

import { Options, Vue } from 'vue-class-component';
import ContainerLayout from '@/components/layout/ContainerLayout.vue';
import SectionCard from '@/components/common/SectionCard.vue';

@Options({
    components: {
        ContainerLayout,
        SectionCard,
    },
})
export default class Technician extends Vue {}


import { Options, Vue } from 'vue-class-component';

import { CurrentUserStore } from '@/store';
import CurrentUserStoreModule from '@/store/CurrentUserStoreModule';

import HorizontalCollapse from '@/components/common/HorizontalCollapse.vue';
import MenuItem from '@/entities/MenuItem';

@Options({
    name: 'Menu',
    components: {
        HorizontalCollapse
    }
})
export default class Menu extends Vue {
    // Internal Properties
    protected currentUserStore?: CurrentUserStoreModule;
    protected isAuthenticated = false;

    protected menuItems: MenuItem[] = [];

    // Internal Methods
    protected restore(): void {
        this.isAuthenticated = this.currentUserStore?.isAuthenticated || false;
    }

    protected async fetchData(): Promise<void> {
        this.setVisibleMenuItems();
    }

    protected activateMenuItem(menuItem: MenuItem): void {
        this.deselectAllMenuItems();
        menuItem.isSelected = true;
        
        if (!menuItem.isNavigation) return;
            
        let parentMenuItem = this.findParentForMenuItem(menuItem.parentPath);

        if (parentMenuItem) {
            parentMenuItem.isSelected = true;
        }

        this.menuItems
            .forEach(x => {
                let parentMenuItem = x.children
                    .find(y => y.name == menuItem.parentName) as MenuItem;
            
                if (parentMenuItem) {
                    parentMenuItem.isSelected = true;
                    return;
                }
            });
    }

    protected deselectAllMenuItems(): void {
        this.menuItems
            .forEach(x => {
                x.isSelected = false;
                x.children
                    .forEach(y => {
                        y.isSelected = false;
                        y.children.forEach(z => z.isSelected = false)
                    })
            });
    }

    protected findParentForMenuItem(parentPath: string): MenuItem {
        return this.menuItems
            .find(x => 
                x.isParent && x.path === parentPath
            ) as MenuItem;
    }

    protected setVisibleMenuItems(): void {
        this.menuItems = this.$router
            .getRoutes()
            .filter(r => {
                if (r.meta.menu === undefined 
                    || (r.meta.menu as any)?.visible === false) {
                    return false;
                }

                if (r.meta.authorize 
                    && (r.meta.authorize as any)?.isAuthorized === false) {
                    return false;
                }

                return true;
            })
            .sort((a, b) => (a.name as string) > (b.name as string)
                ? 1
                : (a.name as string) < (b.name as string)
                    ? -1
                    : 0
            )
            .map(route => {
                let menuItem = new MenuItem(route, true);
                
                route.children
                    .forEach(child => {
                        let newMenuItem = new MenuItem(child, false, route.path);

                        if (child.children) {
                            child.children.forEach(child2 => {
                                newMenuItem.children
                                    .push(new MenuItem(child2, 
                                        false, 
                                        route.path, 
                                        child.name as string));
                            })
                        }

                        menuItem.children.push(newMenuItem); 
                    });

                return menuItem;
            });
    }

    // Lifecycle Hooks
    async created(): Promise<void> {
        this.currentUserStore = await CurrentUserStore;
        
        this.restore();
        await this.fetchData();
        
        this.$watch(
            () => this.currentUserStore?.isAuthenticated,
            async () => {
                this.restore();
                await this.fetchData();
            },
            { immediate: true }
        );
    }
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02f54908"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "menu-items"
}
const _hoisted_2 = ["to"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "second-level-items"
}
const _hoisted_5 = ["to"]
const _hoisted_6 = { class: "third-level-items" }
const _hoisted_7 = ["to"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.isAuthenticated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (firstLevelItem, firstLevelIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "first-level-items group",
            to: {name: firstLevelItem.name},
            key: firstLevelIndex
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["first-level-item", [
                            {'selected': firstLevelItem.isSelected},
                            {'cursor-default': !firstLevelItem.isNavigation}
                        ]]),
              onClick: ($event: any) => (_ctx.activateMenuItem(firstLevelItem))
            }, _toDisplayString(firstLevelItem.title), 11, _hoisted_3),
            (firstLevelItem.children.length > 0)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(firstLevelItem.children, (secondLevelItem, secondLevelIndex) => {
                    return (_openBlock(), _createElementBlock("li", {
                      to: secondLevelItem.path,
                      key: secondLevelIndex,
                      class: _normalizeClass(["second-level-item", {'selected': secondLevelItem.isSelected}])
                    }, [
                      _createVNode(_component_RouterLink, {
                        class: _normalizeClass({'cursor-default': !secondLevelItem.isNavigation}),
                        to: secondLevelItem.path
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(secondLevelItem.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "to"]),
                      _createElementVNode("ul", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(secondLevelItem.children, (thirdLevelItem, thirdLevelIndex) => {
                          return (_openBlock(), _createElementBlock("li", {
                            to: thirdLevelItem.path,
                            key: thirdLevelIndex,
                            class: _normalizeClass([{'selected': thirdLevelItem.isSelected}, "third-level-item"])
                          }, [
                            _createVNode(_component_RouterLink, {
                              onClick: ($event: any) => (_ctx.activateMenuItem(thirdLevelItem)),
                              class: _normalizeClass({'cursor-default': !thirdLevelItem.isNavigation}),
                              to: thirdLevelItem.path
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(thirdLevelItem.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "class", "to"])
                          ], 10, _hoisted_7))
                        }), 128))
                      ])
                    ], 10, _hoisted_5))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
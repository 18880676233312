
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'Button',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
})
export default class Button extends Vue {
    protected disabled!: boolean;
    protected loading!: boolean;
}


import { Options, Vue } from "vue-class-component";

import { AlertStore, CurrentUserStore } from '@/store';
import CurrentUserStoreModule from '@/store/CurrentUserStoreModule';
// import AlertType from '@/enums/AlertType';
// import Alert from "@/entities/Alert";

import Button from '@/components/common/Button.vue';
import Logo from '@/components/layout/Logo.vue';

@Options({
    name: 'Login',
    components: {
        Button,
        Logo,
    },
})
export default class Login extends Vue {
    // Internal Properties
    protected currentUserStore?: CurrentUserStoreModule;
    protected signingIn = false;
    protected userName = '';
    protected password = '';

    // Internal Methods
    protected async signin(): Promise<void> {
        this.signingIn = true;

        window.setTimeout(async () => {
            this.signingIn = false;
            await this.currentUserStore?.login({ userName:this.userName, password:this.password});

            if (this.currentUserStore?.isAuthenticated) {
                this.$router.push('/accounting');
            } else {
                alert('Invalid username or password!');
                this.password = '';
            }
        }, 1000);
    }

    // Lifecycle Hooks
    async created(): Promise<void> {
        this.currentUserStore = await CurrentUserStore;
    }
}

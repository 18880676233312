export default class CacheItem<T> {
    public name: string;
    public expires: Date;
    public content: T;

    constructor(name: string, expires: Date, content: T) {
        this.name = name;
        this.expires = expires;
        this.content = content;
    }
}


import { Options, Vue } from "vue-class-component";
import { AlertStore } from "@/store";
import AlertType from '@/enums/AlertType';
import Alert from "@/entities/Alert";
import { Emit } from "vue-property-decorator";

@Options({
    name: 'Alerts',
})

export default class Alerts extends Vue {

    @Emit("setBackDrop")
    setBackDrop(isOpen: boolean) {
        return isOpen;
    }

    protected getTitleBgColor(type: AlertType): string {
        switch (type) {
            case AlertType.Information:
                return 'bg-blue-300';
            case AlertType.Success:
                return 'bg-green-300';
            case AlertType.Warning:
                return 'bg-yellow-300';
            case AlertType.Error:
                return 'bg-red-300';
            default:
                return 'bg-gray-200';
        }
    }

    protected getMessageBgColor(type: AlertType): string {
        switch (type) {
            case AlertType.Information:
                return 'bg-blue-200';
            case AlertType.Success:
                return 'bg-green-200';
            case AlertType.Warning:
                return 'bg-yellow-200';
            case AlertType.Error:
                return 'bg-red-200';
            default:
                return 'bg-gray-100';
        }
    }

    protected remove(alert: Alert): void {
        AlertStore.remove(alert);
    }
    
    protected get alerts(): Alert[] {
        return AlertStore.list()
            .filter(a => (a.title || a.message) && 
                a.type == AlertType.Information ||
                a.type == AlertType.Success
            );
    }

    protected get errorAndWarningAlerts(): Alert[] {
        const alertList: Alert[] = AlertStore.list()
            .filter(a => (a.title || a.message) && 
                a.type == AlertType.Error || 
                a.type == AlertType.Warning
            );
        
        this.setBackDrop(alertList.length > 0);
        
        return alertList;
    }

    created(): void {
        setInterval(() => {
            const expiredAlerts: Alert[] = this.alerts
                .filter(a => 
                    a.autoClose === true
                    && a.expiresAt <= Date.now());
            
            for (const alert of expiredAlerts) {
                this.remove(alert);
            }
        }, 1000);
    }
}

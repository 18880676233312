import AppCache from '@/cache/AppCache';
import CacheItem from '@/cache/CacheItem';
import ICacheItemStore from '@/interfaces/ICacheItemStore';

export default class CacheItemStore<T> implements ICacheItemStore<T> {
    private cacheContext: AppCache;
    private tableName: string;
    private currentlyHydrating: string[] = [];

    constructor(tableName: string) {
        this.tableName = tableName;
        this.cacheContext = new AppCache();
    }

    public async find(name: string): Promise<T | undefined> {
        const cacheItem: CacheItem<T> | undefined = await this.findCacheItem(name);
        return cacheItem?.content;
    }

    public async create(name: string, expires: Date, content: T): Promise<void> {
        const cacheItem: CacheItem<T> = new CacheItem<T>(
            name,
            expires,
            JSON.parse(JSON.stringify(content)) // Make sure content is not a Vue Proxy Object
        );

        await this.createCacheItem(cacheItem);
    }

    public async findOrCreate(
        name: string,
        hydrate: () => Promise<CacheItem<T>>,
        transform: (content: any) => T = (content: any): T => content
    ): Promise<T> {
        let cacheItem = await this.findCacheItem(name);

        if (cacheItem !== undefined) {
            const transformed = transform(cacheItem.content);
            console.debug(`[${this.tableName}Cache] Found & transformed item.`, transformed);

            return transformed;
        } else {
            // Debounce simultaneous requests
            if (this.isCurrentlyHydrating(name)) {
                console.debug(`[${this.tableName}Cache] Already hydrating ${name}, wait and retry.`);
                return this.debounce(name);
            }

            console.debug(`[${this.tableName}Cache] No item found. Hydrating ...`);
            this.addCurrentlyHydrating(name);
            
            try {
                cacheItem = await hydrate();
                console.debug(`[${this.tableName}Cache] Hydration complete.`, cacheItem);

                // Override name to make sure it's the same as requested item name
                cacheItem.name = name;

                await this.createCacheItem(cacheItem);
                console.debug(`[${this.tableName}Cache] Added item to cache.`);

                this.removeCurrentlyHydrating(name);

                return cacheItem.content;
            } catch (e: any) {
                this.removeAllCurrentlyHydrating(name);
                console.debug(`[${this.tableName}Cache] Couldn't add item to cache.`);
                throw e;
            }
        }
    }

    public async remove(name: string): Promise<void> {
        await this.cacheContext
            .table(this.tableName)
            .delete(name);
    }

    public async clearTable(tableName?: string): Promise<void> {
        await this.cacheContext
            .table(tableName || this.tableName)
            .clear();
    }

    public async clearTables(tableNames: string[]): Promise<void> {
        for (const tableName of tableNames) {
            this.clearTable(tableName);
        }
    }

    protected async createCacheItem(cacheItem: CacheItem<T>): Promise<void> {
        // Check if expiration is valid if present
        if (cacheItem.expires && cacheItem.expires.getTime() < Date.now()) {
            throw new Error(`[${this.tableName}Cache] Item expiration can't be in the past.`);
        }

        // Set default expiration if expiration is not present
        if (!cacheItem.expires) {
            const expiresAtMilliseconds: number =
                Date.now() + 60 * 60 * 1000; // Add 60 minutes default cache time
            cacheItem.expires = new Date(expiresAtMilliseconds);
        }

        this.cacheContext
            .table(this.tableName)
            .add(cacheItem);
    }

    protected async findCacheItem(name: string): Promise<CacheItem<T> | undefined> {
        // Find item
        const cacheItems = (await this.cacheContext
            .table(this.tableName)
            .toArray())
            .filter((s) => s.name === name);

        if (cacheItems.length === 0) {
            return;
        }

        const cacheItem: CacheItem<T> = cacheItems[0];

        // Check expiration, delete if expired
        if (cacheItem.expires.getTime() < Date.now()) {
            this.remove(cacheItem.name);

            return;
        }

        return cacheItem;
    }

    protected async debounce(name: string): Promise<T> {
        return new Promise((resolve, reject) => {
            let tries = 0;
            const handler: NodeJS.Timeout = setInterval(async () => {
                console.debug(`[${this.tableName}Cache] Retrying to find item ${name}.`);

                if (!this.isCurrentlyHydrating(name)) {
                    console.debug(`[${this.tableName}Cache] Trying to find newly hydrated item ${name}.`);
                    clearInterval(handler);
                    
                    const cacheItem = await this.findCacheItem(name);
                    
                    if (cacheItem !== undefined) {
                        console.debug(`[${this.tableName}Cache] Found newly hydrated item ${name}.`);
                        resolve(cacheItem.content);
                    }
                    else {
                        console.debug(`[${this.tableName}Cache] Couldn't find item ${name}.`);
                    }
                }

                tries++;

                if (tries === 25) {
                    clearInterval(handler);
                    reject(`Couldn't find item ${name} after ${tries} tries.`);
                }
            }, 250);
        });
    }

    protected addCurrentlyHydrating(name: string): void {
        this.currentlyHydrating.push(name);
    }

    protected removeCurrentlyHydrating(name: string): void {
        const index: number = this.currentlyHydrating.indexOf(name);

        if (index === -1) {
            return;
        }

        this.currentlyHydrating.splice(index, 1);

        console.debug(`[${this.tableName}Cache] Removed currently hydrating.`, name, index);
    }
    
    protected removeAllCurrentlyHydrating(name: string): void {
        this.currentlyHydrating = this.currentlyHydrating
            .filter(h => h.indexOf(name) === -1);

        console.debug(`[${this.tableName}Cache] Removed all currently hydrating.`, name);
    }

    protected isCurrentlyHydrating(name: string): boolean {
        return this.currentlyHydrating
            .filter(h => h.indexOf(name) !== -1)
            .length > 0;
    }
}

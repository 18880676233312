// Vue Imports
import { createApp } from 'vue';
import router from './router';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faCalendar,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleNotch,
    faClock,
    faEnvelope,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFileExcel,
    faFilePdf,
    faMapMarkerAlt,
    faPencilAlt,
    faPlus,
    faPrint,
    faSave,
    faSearch,
    faSpinner,
    faStar,
    faTimes,
    faTrash,
    faUnlockAlt,
    faUser,
    faUserPlus,
    faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// App Root Import
import App from './App.vue';

// Stylesheet Import (PostCSS / TailwindCSS)
import './css/main.css';

// Configure Fontawesome
library.add(
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faCalendar,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleNotch,
    faClock,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFileExcel,
    faFilePdf,
    faMapMarkerAlt,
    faPencilAlt,
    faPlus,
    faPrint,
    faSave,
    faSearch,
    faSpinner,
    faStar,
    faTimes,
    faTrash,
    faUnlockAlt,
    faUser,
    faUserPlus,
    faWarehouse,
    faExclamationTriangle
);

// Get rid of Vue Proxy object wrapper
const innerLog = console.log;
console.log = (...args: any[]) => {
    innerLog(
        `%cLOG`,
        'background: rgb(192, 192, 192); color: rgb(12, 12, 12); padding: 0 0.5ex; font-weight: bold;',
        `${new Date().toISOString()}\n`,
        ...args.map(arg => typeof arg === 'object'
            ? JSON.parse(JSON.stringify(arg))
            : arg));
};

const innerDebug = console.debug;
console.debug = (...args: any[]) => {
    innerDebug(
        `%cDEBUG`,
        'background: rgb(32, 64, 192); color: rgb(12, 12, 12); padding: 0 0.5ex; font-weight: bold;',
        `${new Date().toISOString()}\n`,
        ...args.map(arg => typeof arg === 'object'
            ? JSON.parse(JSON.stringify(arg))
            : arg));
};

const innerError = console.error;
console.error = (...args: any[]) => {
    innerError(
        `%cERROR`,
        'background: rgb(192, 64, 32); color: rgb(12, 12, 12); padding: 0 0.5ex; font-weight: bold;',
        `${new Date().toISOString()}\n`,
        ...args.map(arg => typeof arg === 'object' && !(arg instanceof Error)
            ? JSON.parse(JSON.stringify(arg))
            : arg));
};

// Disable console in production
if (process.env.NODE_ENV === 'production') {
    console.debug = () => null;
    console.error = () => null;
}

// Debug environment variables
console.debug('[Environment]', process.env);

createApp(App)
    .use(router)
    .component('FontAwesome', FontAwesomeIcon)
    .mount('#app');

import { Options, Vue } from 'vue-class-component';

import { CurrentUserStore } from '@/store';
import CurrentUserStoreModule from '@/store/CurrentUserStoreModule';

import Button from '@/components/common/Button.vue';
import Logo from './Logo.vue';

@Options({
    name: 'Header',
    components: {
        Button,
        Logo,
    },
})
export default class Header extends Vue {
    // Internal Properties
    protected currentUserStore?: CurrentUserStoreModule;
    protected isAuthenticated = false;

    protected signingOut = false;

    // Internal Methods
    protected restore(): void {
        this.isAuthenticated = this.currentUserStore?.isAuthenticated || false;
    }
    
    protected async signout(): Promise<void> {
        this.signingOut = true;
        window.setTimeout(() => {
            this.signingOut = false;
            this.currentUserStore?.logout();
            this.$router.push('/');
        }, 2000);
    }

    // Lifecycle Hooks
    async created(): Promise<void> {
        this.currentUserStore = await CurrentUserStore;
        
        this.restore();

        this.$watch(
            () => this.currentUserStore?.isAuthenticated,
            () => {
                this.restore();
            }
        );
    }
};

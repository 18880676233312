export const hoursFromNow = (hours: number | string | null, defaultHours: number | string = 1): Date => {
    if (hours !== null) {
        hours = typeof hours === 'string'
            ? parseInt(hours)
            : hours;
    }

    defaultHours = typeof defaultHours === 'string'
        ? parseInt(defaultHours)
        : defaultHours;
    
    return new Date(Date.now() + ((hours ?? defaultHours) * 60 * 60 * 1000));
}

export default {
    hoursFromNow
}

export const formatDate = (date: Date): string => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

export const formatDateTime = (date: Date): string => {
    return `${formatDate(date)} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
};

export const formatDateTimeWithSeconds = (date: Date): string => {
    return `${formatDate(date)} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
};

export const formatTimezoneOffset = (offset: number): string => {
    const absOffset = Math.abs(offset);

    const minutesInDecimal: number = absOffset % 1;
    const hours: number = absOffset - minutesInDecimal;
    const minutes: number = minutesInDecimal > 0 ? 60 * minutesInDecimal : 0;

    const sign = offset >= 0 ? '+' : '-';

    return `GMT ${sign}${hours}:${String(minutes).padStart(2, '0')}`;
};

export const formatDollarAmount = (amount: number): string => {
    return new Intl.NumberFormat(
        'en-CA',
        {
            currency: 'CAD',
            style: 'currency'
        })
        .format(amount);
}

export const splitStringOnUppercaseChars = (value: string): string => {
    if (typeof value !== 'string' || value === null) {
        throw new Error('Missing required value argument.');
    }

    const matches: string[] = value.match(/([A-Z]?[^A-Z]*)/g) ?? [];

    // If no matches were found, fallback to input value
    return matches.length > 0 ? matches.slice(0, -1).join(' ') : value;
};

export default {
    formatDate,
    formatDateTime,
    formatDateTimeWithSeconds,
    formatTimezoneOffset,
    formatDollarAmount,
    splitStringOnUppercaseChars,
};

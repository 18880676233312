<template>
    <div
        class="
            login-background
            flex
            justify-center
            items-center
            content-center
            flex-auto
        "
    >
        <LoginCard />
    </div>
</template>

<script>
import Login from '../components/authorization/Login';

// @ is an alias to /src
export default {
    components: {
        LoginCard: Login,
    },
    name: 'Login',
};
</script>

<style lang="postcss" scoped>
.login-background {
    background-image: url('../assets/login-background.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}
</style>

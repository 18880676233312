import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd2b8362"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-center content-center items-center bg-white w-96 shadow-lg" }
const _hoisted_2 = { class: "flex-initial p-8" }
const _hoisted_3 = { class: "mb-6" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = { class: "flex flex-initial justify-center w-full p-8 pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Logo)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.signin && _ctx.signin(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control fields",
          placeholder: "Username",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.userName]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "password",
          class: "form-control fields",
          placeholder: "Password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
        }, null, 512), [
          [_vModelText, _ctx.password]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          class: "w-36 uppercase text-lg font-bold",
          disabled: _ctx.userName.trim() == '' || _ctx.password.trim() == '',
          loading: _ctx.signingIn
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Sign In ")
          ]),
          _: 1
        }, 8, ["disabled", "loading"])
      ])
    ], 32)
  ]))
}
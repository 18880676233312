
import { Options, Vue } from 'vue-class-component';
import { capitalize } from 'lodash'

import { formatDateTime } from '@/helpers/CustomFormatters';

@Options({
    name: 'EnvironmentRibbon',
})
export default class EnvironmentRibbon extends Vue {
    private environment: string = process.env.VUE_APP_ENVIRONMENT?.toLowerCase() ?? 'production';
    private buildnumber: string = process.env.VUE_APP_BUILD ?? 'debug-build';
    
    private get environmentDisplay(): string {
        return capitalize(this.environment);
    }

    private get currentDateDisplay(): string {
        return formatDateTime(new Date());
    }
}

import { createRouter, createWebHashHistory } from 'vue-router';

import StandaloneRoutes from '@/views/routes';
import AccountingRoutes from '@/views/accounting/routes';
import InvoicingRoutes from '@/views/invoicing/routes';
import { CurrentUserStore } from '@/store';

// Routes are private by default. Add meta.isPublic = true
// attribute to allow a route to be publicly accessible.
const routes = [
    ...StandaloneRoutes, // Needs to be first in routes object, don't move!
    ...AccountingRoutes,
    ...InvoicingRoutes,
    // If a 404 / fallback route is desired it will
    // have to be inserted last in this object
    // in order for route matching to work correctly.
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// async function userCanAccess(to: any) {
//     console.debug('[Router] userCanAccess: to', to);

//     const isPublicRoute = to.matched.some(
//         (route: any) => route.meta?.isPublic === true
//     );

//     if (isPublicRoute) {
//         console.debug('[Router] userCanAccess: is public route');
//         return true;
//     }

//     const options = to.meta?.authorize as IAuthorizerOptions;

//     if (!options) {
//         console.debug('[Router] userCanAccess: authorized without options');
//         return CurrentUserStore.isAuthenticated;
//     }

//     console.debug('[Router] userCanAccess: authorizing with options', options);

//     return await AuthorizeUser(options);
// }

router.beforeEach(async (to) => {
    
    const currentUserStore = await CurrentUserStore;

    if (currentUserStore.isAuthenticated
        && (to.matched.some(route => route.path === '/')
            || to.matched.some(route => route.path === '/login'))) {
        return '/accounting';
    }

    // try {
    //     if (!(await userCanAccess(to))) {
    //         console.debug('[Router] beforeEach: user is authenticated', CurrentUserStore.isAuthenticated);
    //         return CurrentUserStore.isAuthenticated
    //             ? '/unauthorized'
    //             : '/';
    //     }
    // } catch (e: any) {
    //     console.error('[Router] beforeEach: exception', e);
    //     return '/';
    // }

    const title: string = (to.meta.title as string) || (to.name as string);

    document.title = `${title ? `${title} – ` : ''}${process.env.VUE_APP_NAME}`;
});

export default router;

import { VuexModule, Module, Action, Mutation, RegisterOptions } from 'vuex-class-modules';
import AppDatabase from './database';
import ICurrentUser from '@/interfaces/ICurrentUser';

const DbContext = new AppDatabase();

@Module
export default class CurrentUserStoreModule extends VuexModule {
    protected currentUser: ICurrentUser = {
        name: 'current-user',
        authenticated: false
    };
    
    constructor(options: RegisterOptions) {
        super(options);
        console.debug('[CurrentUserStore] Constructed');
    }

    @Action
    public async init(): Promise<CurrentUserStoreModule> {
        const currentUser = await DbContext.CurrentUser.get("current-user");

        this.restore(currentUser || {
            name: 'current-user',
            authenticated: false
        });

        return this;
    }

    @Mutation
    public restore(currentUser: ICurrentUser): void {
        this.currentUser = currentUser;
    }

    @Mutation
    public setAuthenticated(authenticated: boolean): void {
        if (authenticated === undefined) {
            console.error(`[CurrentUserStore] Tried to add undefined authenticated.`);
            return;
        }

        const currentUser = this.getCurrentUser();
        currentUser.authenticated = authenticated;
        
        this.currentUser = currentUser;
        DbContext.CurrentUser.put(currentUser, 'current-user');

        console.debug('[CurrentUserStore] setAuthenticated; authenticated, this.isAuthenticated:',
            authenticated,
            this.currentUser.authenticated);
    }

    public get isAuthenticated(): boolean {
        console.debug('[CurrentUserStore] get isAuthenticated', this.currentUser.authenticated);
        return this.currentUser.authenticated || false;
    }

    protected getCurrentUser(): ICurrentUser {
        return JSON.parse(JSON.stringify(this.currentUser));
    }

    @Action
    public async login(userInfo: any): Promise<void> {
        const passwordHash = await this.sha512(userInfo.password);

        const authenticated = (process.env.VUE_APP_USER_NAMES ?? 'MDMUser,MDMDev').split(',').includes(userInfo.userName)
            && process.env.VUE_APP_PASSWORD_HASH == passwordHash;
        
        this.setAuthenticated(authenticated);
    }

    @Action
    public async logout(): Promise<void> {
        this.setAuthenticated(false);
    }

    private async sha512(message: string): Promise<string> {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);
    
        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-512', msgBuffer);
        
        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));
    
        // convert bytes to hex string
        const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
        return hashHex;
    }
}

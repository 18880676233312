import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';

import Alert from '@/entities/Alert';
import AlertType from '@/enums/AlertType';

@Module
export default class AlertStoreModule extends VuexModule {
    protected alerts: Alert[] = [];

    @Mutation
    public addAlert(alert: Alert): void {
        if (!alert.title && !alert.message) {
            console.error('Missing required title and/or message.');
        }

        this.alerts.push(alert);
    }
    
    @Action
    public addInformation(props: { message: string, title: string }): void {
        const alert = new Alert(
            props.message,
            props.title,
            AlertType.Information,
            true); // Auto Close

        this.addAlert(alert);
    }

    @Action
    public addSuccess(props: { message: string, title: string }): void {
        const alert = new Alert(
            props.message,
            props.title,
            AlertType.Success,
            true); // Auto Close

        this.addAlert(alert);
    }
    
    @Action
    public addWarning(props: { message: string, title: string }): void {
        const alert = new Alert(
            props.message,
            props.title,
            AlertType.Warning);

        this.addAlert(alert);
    }
    
    @Action
    public addError(props: { message: string, title: string }): void {
        const alert = new Alert(
            props.message,
            props.title,
            AlertType.Error);

        this.addAlert(alert);
    }

    @Action
    public addException(exception: any): void {
        let alert: Alert;

        if (exception.constructor.name === 'ProblemDetails') {
            let message = exception.detail;

            if (exception.type === 'ValidationException') {
                const exceptionMessage = this.getValidationExceptionMessage(exception);
                message += exceptionMessage;
            }

            // Automatically close if record not found warning
            const autoClose = exception.type === 'RecordNotFoundException';

            let exceptionType = AlertType.Error;

            if (exception.status == 409 || exception.type === 'RecordNotFoundException') {
                exceptionType = AlertType.Warning
            } 

            alert = new Alert(
                message,
                `${exception.status}: ${exception.title}`,
                exceptionType,
                autoClose);
        }
        else if (typeof exception === 'string') {
            alert = new Alert(
                exception,
                'Unknown Error',
                AlertType.Error);
        }
        else if (typeof exception === 'object') {
            const response = exception.response
                ? JSON.parse(exception.response)
                : exception;
            
            if (!response.type || !response.detail) {
                this.addError({
                    title: response.title ?? "Unkown error",
                    message: response.details ?? "No detailed error information available."
                });

                return;
            }

            const exceptionType = response.type;
            let alertType = AlertType.Error;
            let message = response.detail;

            if (exceptionType === 'ValidationException') {
                const exceptionMessage = this.getValidationExceptionMessage(response);
                message += exceptionMessage;
            }

            if (response.status === 404 || response.status === 409) {
                alertType = AlertType.Warning;
            }

            alert = new Alert(
                message,
                `${response.status}: ${response.title}`,
                alertType,
                false);
        }
        else {
            alert = new Alert(
                exception.message,
                exception.name,
                AlertType.Error);
        }
        
        this.addAlert(alert);
    }

    private getValidationExceptionMessage(exception: any): string {
        
        let message = "";
        
        const validationErrors: {
            [key: string]: string[]
        } | undefined = exception.errors;

        if (validationErrors) {
            console.debug(
                '[AlertStore] validationErrors',
                validationErrors);

            for (const key of Object.keys(validationErrors)) {
                console.debug(
                    `[AlertStore] validationErrors[${key}]`,
                    validationErrors[key]);

                const errorItems = validationErrors[key]
                    .map(e => `<li>${e}</li>`)
                    .join('');

                message += `
                    <div class="pt-2">
                        <div class="font-bold">${key}:</div>
                        <ul class="list-inside list-disc">
                            ${errorItems}
                        </ul>
                    </div>`;
            }
        }

        return message;
    }

    @Mutation
    public remove(alert: Alert): void {
        this.alerts = this.alerts
            .filter(a => a.alertId !== alert.alertId);
    }

    @Mutation
    public purge(): void {
        this.alerts = [];
    }

    public list(): Alert[] {
        return this.alerts;
    }
}
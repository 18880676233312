import Dexie from 'dexie';
import ICurrentUser from '@/interfaces/ICurrentUser';
import ISectionCardState from '@/interfaces/ISectionCardState';

export default class AppDatabase extends Dexie {
    public CurrentUser!: Dexie.Table<ICurrentUser, string>;
    public SectionCardStates!: Dexie.Table<ISectionCardState, string>;

    constructor() {
        super('AppDatabase');

        this.version(2).stores({
            CurrentUser: 'name',
            SectionCardStates: 'name',
        });
    }
}

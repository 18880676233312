<template>
    <div
        class="select-none"
        v-bind:class="{
            'text-6xl': size === 'large',
            'text-4xl': size === 'small',
        }"
    >
        <span class="font-bold text-blue-900 ">MDM</span><span class="inline-block ml-1 text-blue-700">CASE</span>
    </div>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        size: {
            type: String,
            default: 'large',
        },
    },
};
</script>

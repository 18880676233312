import { VuexModule, Module, Action, Mutation, RegisterOptions } from 'vuex-class-modules';

import CacheItemStoreProvider from '@/cache/CacheItemStoreProvider';
import ICacheItemStore from '@/interfaces/ICacheItemStore';
import { hoursFromNow } from '@/helpers/DateHelpers';
import { LocationDto } from '@/clients/ClientManagementApiClient';

@Module
export default class CurrentLocationsStoreModule extends VuexModule {
    protected locationsValue: LocationDto[] = [];
    
    protected currentLocationsCache?: ICacheItemStore<LocationDto[]>;
    protected name = 'currentLocations';

    constructor(options: RegisterOptions) {
        super(options);
        this.currentLocationsCache = CacheItemStoreProvider
            .Get<LocationDto[]>('CurrentLocations');

        console.debug('[CurrentLocationsStore] Constructed');
    }

    @Mutation
    public setLocations(locations: LocationDto[]): void {
        console.debug('[CurrentLocationsStore] setLocations', locations);
        this.locationsValue = locations;
    }

    public get locations(): LocationDto[] {
        return this.locationsValue;
    }

    @Action
    public async set(locations: LocationDto[]): Promise<void> {
        await this.invalidate();
        
        const expires = hoursFromNow(process.env.VUE_APP_CACHE_DURATION_CURRENTLOCATION || 1);
        await this.currentLocationsCache
            ?.create(this.name, expires, locations);
        
        console.debug('[CurrentLocationsStore] set', this.name, expires, locations);
        
        this.setLocations(locations);
    }

    @Action
    public async load(): Promise<void> {
        const locations = await this.currentLocationsCache
            ?.find(this.name) || [];

        console.debug(
            '[CurrentLocationsStore] load; locations, this.locations',
            locations,
            this.locations);

        this.setLocations(locations);
    }

    @Action
    public async invalidate(): Promise<void> {
        await this.currentLocationsCache
            ?.remove(this.name);

        console.debug('[CurrentLocationsStore] invalidate');
        
        this.setLocations([]);
    }
}


import { Options, Vue } from 'vue-class-component';

import { SectionCardStateStore } from '@/store';
import SectionCardStateStoreModule from '@/store/SectionCardStateStoreModule';
import ISectionCardState from '@/interfaces/ISectionCardState';

import Button from '@/components/common/Button.vue';

@Options({
    components: {
        Button,
    },
    emits: ['update:collapsed'],
    name: 'SectionCard',
    props: {
        collapsible: {
            type: Boolean,
            default: false
        },
        collapsed: Boolean,
        name: String,
        flipButton: {
            type: Boolean,
            default: false
        }
    }
})
export default class SectionCard extends Vue {
    // Public Properties
    public collapsible!: boolean;
    public collapsed!: boolean;
    public name!: string;
    public flipButton!: boolean;

    // Internal Properties
    protected store?: SectionCardStateStoreModule;
    protected innerCollapsed = true;

    // Internal Methods
    protected toggleCollapsedState(): void {
        this.innerCollapsed = !this.innerCollapsed;
        
        // If collapsible is set from outside let that
        // component handle state via event handler (e.g. groups).
        this.$emit('update:collapsed', this.innerCollapsed);

        if (this.name) {
            this.store?.upsert({
                name: this.name, // default Date.now
                collapsed: this.innerCollapsed,
            } as ISectionCardState);
        }
    }

    protected restore(): void {
        const currentState = this.name
            ? this.store?.find(this.name)
            : null;
        
        this.innerCollapsed = currentState?.collapsed ?? (this.collapsed || false);
    }

    // Computed Properties
    protected get isCollapsed(): boolean {
        return this.innerCollapsed;
    }

    // Lifecycle hooks
    async created(): Promise<void> {
        this.store = await SectionCardStateStore;
        setTimeout(this.restore, 100);
    }
}

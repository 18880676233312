const InvoicingRoutes = [
    {
        path: '/invoicing',
        name: 'InvoicingMain',
        component: (): any =>
            import(
                /* webpackChunkName: "Invoicing" */ './Main.vue'
            ),
        meta: {
            title: 'Invoicing',
            isNavigation: false,
            menu: {
                isPublic: true,
                isVisible: true,
            }
        },
        children: [{
            path: '',
            name: 'ASO',
            component: (): any =>
                import(
                    /* webpackChunkName: "Invoicing" */ './aso/AsoInvoicing.vue'
                ),
            meta: {
                title: 'ASO',
                isPublic: true,
                isVisible: true,
                isNavigation: false
            },
            children: [{
                path: '/invoicing/aso/generate',
                name: 'GenerateAsoInvoicing',
                component: (): any =>
                    import(
                        /* webpackChunkName: "Invoicing" */ './aso/AsoInvoicing.vue'
                    ),
                props: {
                    asoType: 'generate'
                },
                meta: {
                    title: 'Generate ASO Invoicing',
                    isPublic: true,
                    isVisible: true,
                    isNavigation: true                    
                },
            },{
                path: '/invoicing/aso/historical',
                name: 'HistoricalAsoInvoicing',
                component: (): any =>
                    import(
                        /* webpackChunkName: "Invoicing" */ './aso/AsoInvoicing.vue'
                    ),
                props: {
                    asoType: 'historical'
                },
                meta: {
                    title: 'Historical ASO Invoicing',
                    isPublic: true,
                    isVisible: true,
                    isNavigation: true
                }
            }        
            ]
        }
        ,{
            path: '',
            name: 'InsuredInvoicing',
            component: (): any =>
                import(
                    /* webpackChunkName: "Invoicing" */ './insured/InsuredInvoicing.vue'
                ),
            meta: {
                title: 'Insured',
                isPublic: true,
                isVisible: true,
                isNavigation: false
            },
            children: [{
                path: '/invoicing/insured/generate',
                name: 'GenerateInsuredInvoicing',
                component: (): any =>
                    import(
                        /* webpackChunkName: "Invoicing" */ './insured/InsuredInvoicing.vue'
                    ),
                meta: {
                    title: 'Generate Insured Invoicing',
                    isPublic: true,
                    isVisible: true,
                    isNavigation: true
                }
            },{
                path: '/invoicing/insured',
                name: 'HistoricalInsuredInvoicing',
                component: (): any =>
                    import(
                        /* webpackChunkName: "Invoicing" */ './insured/InsuredInvoicing.vue'
                    ),
                meta: {
                    title: 'Historical Insured Invoicing',
                    isPublic: true,
                    isVisible: true,
                    isNavigation: true
                }
            }]     
        }        
        ]
    },
];

export default InvoicingRoutes;
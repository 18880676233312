import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesome = _resolveComponent("FontAwesome")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col", {
            'overflow-hidden w-12': !_ctx.isOpen
        }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex-initial", `
                ${ _ctx.backgroundColor }
                p-2
                ${ _ctx.textColor }
                cursor-pointer
                select-none`]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["whitespace-nowrap", {
                    'transform rotate-180': !_ctx.isOpen
                }])
      }, [
        _createVNode(_component_FontAwesome, {
          class: "inline-block",
          icon: "chevron-left",
          "fixed-width": ""
        }),
        _createVNode(_component_FontAwesome, {
          class: "inline-block -ml-3",
          icon: "chevron-left",
          "fixed-width": ""
        })
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-auto", { 'hidden': !_ctx.isOpen }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}
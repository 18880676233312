
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'HorizontalCollapse',
    props: {
        backgroundColor: {
            type: String,
            default: 'bg-gray-700'
        },
        textColor: {
            type: String,
            default: 'text-gray-200'
        }
    }
})
export default class HorizontalCollapse extends Vue {
    public backgroundColor!: string;
    public textColor!: string;
    protected isOpen = true;
}

import { createStore } from 'vuex';

import AlertStoreModule from './AlertStoreModule';
import SectionCardStateStoreModule from './SectionCardStateStoreModule';
import CurrentUserStoreModule from './CurrentUserStoreModule';
import CurrentLocationsStoreModule from './CurrentLocationsStoreModule';

const store = createStore({});

// tslint:disable-next-line:no-unused-expression
export const AlertStore = new AlertStoreModule({ store, name: 'alert' });

// tslint:disable-next-line:no-unused-expression
export const SectionCardStateStore = new SectionCardStateStoreModule({
    store,
    name: 'section-card-state',
}).init();

// tslint:disable-next-line:no-unused-expression
export const CurrentUserStore = new CurrentUserStoreModule({
    store,
    name: 'current-user'
}).init();

// tslint:disable-next-line:no-unused-expression
export const CurrentLocationsStore = new CurrentLocationsStoreModule({ store, name: 'location' });

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50" }
const _hoisted_2 = { class: "w-full bg-white rounded shadow-md overflow-hidden" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "fixed right-8 bottom-8 z-50" }
const _hoisted_6 = { class: "w-full bg-white rounded shadow-md overflow-hidden" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesome = _resolveComponent("FontAwesome")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorAndWarningAlerts, (alert, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["w-96 max-w-full relative", { 'mt-4': index > 0 }]),
          key: index
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["px-4 py-2 pr-12", `${_ctx.getTitleBgColor(alert.type)} ${alert.message ? 'border-b' : ''}`])
            }, [
              _createElementVNode("strong", null, _toDisplayString(alert.title), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["px-4 py-2 pr-12", _ctx.getMessageBgColor(alert.type)]),
              innerHTML: alert.message
            }, null, 10, _hoisted_3)
          ]),
          _createElementVNode("div", {
            class: "flex items-center justify-center bg-gray-600 text-white w-6 h-6 border rounded-full shadow absolute top-2 right-2 cursor-pointer select-none",
            onClick: ($event: any) => (_ctx.remove(alert))
          }, [
            _createVNode(_component_FontAwesome, {
              icon: "times",
              "fixed-width": ""
            })
          ], 8, _hoisted_4)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["w-96 max-w-full relative", { 'mt-4': index > 0 }]),
          key: index
        }, [
          _createElementVNode("div", _hoisted_6, [
            (alert.title)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["px-4 py-2 pr-12", `${_ctx.getTitleBgColor(alert.type)} ${alert.message ? 'border-b' : ''}`])
                }, [
                  _createElementVNode("strong", null, _toDisplayString(alert.title), 1)
                ], 2))
              : _createCommentVNode("", true),
            (alert.message)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["px-4 py-2 pr-12", _ctx.getMessageBgColor(alert.type)]),
                  innerHTML: alert.message
                }, null, 10, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "flex items-center justify-center bg-gray-600 text-white w-6 h-6 border rounded-full shadow absolute top-2 right-2 cursor-pointer select-none",
            onClick: ($event: any) => (_ctx.remove(alert))
          }, [
            _createVNode(_component_FontAwesome, {
              icon: "times",
              "fixed-width": ""
            })
          ], 8, _hoisted_8)
        ], 2))
      }), 128))
    ])
  ], 64))
}
const AccountingRoutes = [
    {
        path: '/accounting',
        name: 'AccountingDashboard',
        component: (): any =>
            import(
                /* webpackChunkName: "Accounting" */ './Dashboard.vue'
            ),
        meta: {
            title: 'Accounting',
            isNavigation: false,
            menu: {
                isPublic: true,
                isVisible: true
            }
        },
        children:[]
    },
];

export default AccountingRoutes;

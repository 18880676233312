import AlertType from "@/enums/AlertType";
import IAlert from "@/interfaces/IAlert";

import { v4 as Uuid } from 'uuid';

export default class Alert implements IAlert {
    public alertId: string;
    public message: string;
    public title: string;
    public type: AlertType;
    public autoClose: boolean;
    public autoCloseDuration: number;
    public expiresAt: number;

    constructor(
        message: string,
        title: string,
        type: AlertType = AlertType.Information,
        autoClose = false,
        autoCloseDuration = 10,
    ) {
        this.alertId = Uuid();
        this.type = type;
        this.title = title;
        this.message = message;
        this.autoClose = autoClose;
        this.autoCloseDuration = autoCloseDuration;
        this.expiresAt = Date.now() + (this.autoCloseDuration * 1000);
    }
}

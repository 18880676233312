
import { Options, Vue } from 'vue-class-component';

import { CurrentUserStore } from '@/store';
import CurrentUserStoreModule from '@/store/CurrentUserStoreModule';

import Alerts from '@/components/layout/Alerts.vue';
import EnvironmentRibbon from '@/components/layout/EnvironmentRibbon.vue';
import Header from '@/components/layout/Header.vue';
import Menu from '@/components/layout/Menu.vue';

@Options({
    name: 'App',
    components: {
        Alerts,
        EnvironmentRibbon,
        Header,
        Menu,
    }
})
export default class App extends Vue {
    // Internal Properties
    protected currentUserStore?: CurrentUserStoreModule;
    protected isAuthenticated = false;
    protected showBackDrop = false;

    // Internal Methods
    protected restore(): void {
        this.isAuthenticated = this.currentUserStore?.isAuthenticated || false;
    }

    protected setBackDrop(showBackDrop: boolean) {
        this.showBackDrop = showBackDrop;
    }

    // Lifecycle Hooks
    async created(): Promise<void> {
        this.currentUserStore = await CurrentUserStore;

        this.restore();

        this.$watch(
            () => this.currentUserStore?.isAuthenticated,
            () => {
                this.restore();
            }
        );
    }
}

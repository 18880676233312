import Dexie from 'dexie';
import CacheItem from '@/cache/CacheItem';

export default class AppCache extends Dexie {
    public CacheItems!: Dexie.Table<CacheItem<any>, string>;

    constructor() {
        super('AppCache');

        this.version(1).stores({
            'CurrentLocations': 'name',
            'CurrentUser': 'name',
            'Organizations': 'name',
            'Roles': 'name',
            'Timezones': 'name',
            'Users': 'name'
        });
    }
}

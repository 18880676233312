import { RouteRecordRaw } from "vue-router";

export default class MenuItem {
    public path: string;
    public title: string;
    public isSelected = false;
    public isParent: boolean;
    public children: MenuItem[] = [];
    public parentPath: string;
    public parentName: string;
    public name: string;
    public isNavigation = true;

    constructor(route: RouteRecordRaw, isParent: boolean, parentPath = "", parentName = "") {
        this.path = route.path;
        this.title = (route.meta?.menu as any)?.title || route.meta?.title || route.name;
        this.isParent = isParent;
        this.parentPath = parentPath;
        this.parentName = parentName;
        this.name = route.name as string;
        this.isNavigation = route.meta?.isNavigation as boolean;
    }
}

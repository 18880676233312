import CacheItemStore from '@/cache/CacheItemStore';
import ICacheItemStore from '@/interfaces/ICacheItemStore';
import IDictionary from '@/interfaces/IDictionary';

const cacheItemStoreSingletons: IDictionary<ICacheItemStore<any>> = {};

export default class CacheItemStoreProvider {
    public static Get<T>(tableName: string): ICacheItemStore<T> {
        if (!tableName) {
            throw new Error('Missing required tableName argument.');
        }

        if (!cacheItemStoreSingletons[tableName]) {
            console.debug(`Creating new singleton for CacheItemStore<T>(${tableName})`);
            cacheItemStoreSingletons[tableName] = new CacheItemStore<T>(tableName);
        }

        return cacheItemStoreSingletons[tableName];
    }
}

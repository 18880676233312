import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import AppDatabase from './database';
import ISectionCardState from '@/interfaces/ISectionCardState';

const DbContext = new AppDatabase();

@Module
export default class SectionCardStateStoreModule extends VuexModule {
    protected states: ISectionCardState[] = [];

    @Action
    public async init(): Promise<SectionCardStateStoreModule> {
        const states = await DbContext.SectionCardStates.toArray();
        this.restore(states);

        return this;
    }

    @Mutation
    public upsert(state: ISectionCardState): void {
        const savedState = this.getState(state.name);

        if (savedState !== undefined) {
            savedState.collapsed = state.collapsed;
            DbContext.SectionCardStates.update(state.name, {
                collapsed: state.collapsed,
            });
        } else {
            this.states.push(state);
            DbContext.SectionCardStates.add(state);
        }
    }

    @Mutation
    public restore(states: ISectionCardState[]): void {
        this.states = states;
    }

    public get find(): (name: string) => ISectionCardState | undefined {
        return this.getState;
    }

    protected getState(name: string): ISectionCardState | undefined {
        const matches = this.states.filter((s) => s.name === name);
        return matches.length > 0 ? matches[0] : undefined;
    }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["environment-ribbon", _ctx.environment])
    }, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.environmentDisplay), 1),
      _createTextVNode(" (" + _toDisplayString(_ctx.buildnumber) + ", " + _toDisplayString(_ctx.currentDateDisplay) + ") ", 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["environment-background", _ctx.environment])
    }, _toDisplayString(_ctx.environmentDisplay), 3)
  ], 64))
}
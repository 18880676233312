import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e447510"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-bold text-right px-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesome = _resolveComponent("FontAwesome")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["section-card bg-white w-full p-8 shadow-md relative", { 'pb-12': !_ctx.isCollapsed }])
  }, [
    (_ctx.collapsible)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: _normalizeClass(["blue absolute right-0 top-0 transform rotate-0 z-10", { 'rotate-180': _ctx.isCollapsed }]),
          onClick: _ctx.toggleCollapsedState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FontAwesome, {
              class: _normalizeClass({ 'transform rotate-180': _ctx.flipButton }),
              icon: "chevron-up"
            }, null, 8, ["class"])
          ]),
          _: 1
        }, 8, ["class", "onClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ 'h-0 overflow-hidden': _ctx.isCollapsed })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass({ 'h-0 overflow-hidden': !_ctx.isCollapsed })
    }, [
      _renderSlot(_ctx.$slots, "collapsed", {}, () => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(" Click to open "),
          _createVNode(_component_FontAwesome, {
            icon: "arrow-right",
            class: "transform -rotate-45"
          })
        ])
      ], true)
    ], 2)
  ], 2))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center content-between bg-gray-200 w-full h-20 border-b border-gray-200 shadow-md absolute top-0 left-0 right-0 z-40"
}
const _hoisted_2 = { class: "flex flex-auto items-center h-full px-8" }
const _hoisted_3 = { class: "flex flex-auto items-center justify-end h-full px-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isAuthenticated)
    ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Logo, { size: "small" })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            class: "w-36 red uppercase text-lg font-bold",
            loading: _ctx.signingOut,
            onClick: _ctx.signout
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Sign Out ")
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}